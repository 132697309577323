module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/8Ticon-400x400.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3bfed3e1fdbc7ed07ac04b6d54256e95"},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-position/gatsby-browser.js'),
      options: {"plugins":[],"elements":[".class-selector","#id-selector","[data-testid=\"test-selector\"]",{"selector":"#fallback-position","ensureInView":"#fallback-position ul li.scroll-to-me"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-rudderstack/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"24T4RdkhkFMEjeDt1rcRrtaaAPy","devKey":"2JwIKs0Q9iiJRIioCvltVoKYEDH","trackPage":true,"trackPageDelay":50,"dataPlaneUrl":"https://twelvedeqggt.dataplane.rudderstack.com","delayLoad":false,"delayLoadTime":5000,"manualLoad":false,"sdkURL":"https://8twelve.mortgage/dataplane.min.js","loadType":"defer"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
